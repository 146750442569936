import React, { useState } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import { Swiper, SwiperSlide, Autoplay, Pagination, TypeSwiper } from '../../services/ImportsSwiper';

import CardTeam from '../../components/CardTeam/CardTeam';
import './SliderTeam.scss';
import './SliderTeam-media.scss';
import { Props } from './interfaces';

const SliderTeam = ({ slides, widthWindow }: Props) => {
  const [numActiveSlide, setNumActiveSlide] = useState(0);

  const Changed = (current: TypeSwiper) => setNumActiveSlide(current.activeIndex);

  const [slider, setSlider] = useState<TypeSwiper>();

  const settings = {
    autoplay: false,
    speed: 500,
    loop: true,
    modules: [Autoplay, Pagination],
    onSlideChange: Changed,
    spaceBetween: 8,
    className: 'slider-team',
    centeredSlides: true,
    slidesPerView: widthWindow > 720 ? 3.1 : 1.1,
    slideToClickedSlide: true,
  };

  return (
    <div className="slider-team">
      <Swiper {...settings} onInit={(slider: TypeSwiper) => setSlider(slider)}>
        {slides.map((post, i) => (
          <SwiperSlide key={i}>
            <CardTeam {...post} key={i} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(SliderTeam));
