import { TextBlock } from '../cases/TextBlock/TextBlock';
import { IframeVideo } from '../components/BlockVideo/IframeVideo';

export const ConstructorCases = ({ nameBlock, content }: { nameBlock: string; content: any }) => {
  switch (nameBlock) {
    case 'Текст': {
      return <TextBlock text={content.text} />;
    }
    case 'Код для вставки видео': {
      return <IframeVideo iframe={content.video_code} />;
    }
    default:
      return null;
  }
};
