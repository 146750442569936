import { IMenu } from '../../../redux/initState/InterfacesState';
import { EnumUrlsPages } from '../../../services/Enum';

export const MenuTopList: IMenu[] = [
  { text: 'Проекты', link: EnumUrlsPages.projects, intoLink: false, hash: false },
  { text: 'Услуги', link: EnumUrlsPages.services, intoLink: false, hash: false },
  { text: 'Агентство', link: EnumUrlsPages.agency, intoLink: false, hash: false },
  { text: 'Контакты', link: EnumUrlsPages.contacts, intoLink: false, hash: false },
  { text: 'Телеграм', link: 'https://t.me/mtg_agency', intoLink: true, hash: false },
];
