import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Props } from './interfaces';

const LinkMenu = ({ menu, className, children, onClickLink }: Props) => {
  let location = useLocation().pathname;

  const { link, text, intoLink } = menu;

  return (
    <div
      className={`menu-top__item-menu${link === location ? ' menu-top__item-menu_active' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      {link.length ? (
        intoLink ? (
          <a
            href={link}
            target="_blank"
            className="buttonM menu-top__link"
            onClick={onClickLink ? onClickLink : () => {}}
            dangerouslySetInnerHTML={{ __html: text }}
          ></a>
        ) : (
          <Link
            to={link}
            className="buttonM menu-top__link"
            onClick={onClickLink ? onClickLink : () => {}}
            dangerouslySetInnerHTML={{ __html: text }}
          ></Link>
        )
      ) : (
        <p className="buttonM menu-top__link" dangerouslySetInnerHTML={{ __html: text }}></p>
      )}

      {children}
    </div>
  );
};

export default LinkMenu;
