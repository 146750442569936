import { IAccordion } from '../../components/Accordion/interfaces';
import { IModxService } from '../../redux/services/InterfacesModx';
import { EnumUrlsPages } from '../../services/Enum';

export const formatServices = (services: IModxService[]): IAccordion[] => {
  const result: IAccordion[] = [];
  services.forEach(({ name, description }, i) => {
    result.push({
      title: name,
      content: description,
      link: EnumUrlsPages.projects,
    });
  });

  return result;
};
