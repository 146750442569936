import { ITabCategory } from '../../components/Tabs/interfaces';
import { IModxCasePage } from '../../redux/services/InterfacesModx';

export const Filters1: ITabCategory[] = [{ text: 'туризм', tab: 'tourism' }];

export const Filters2: ITabCategory[] = [
  { text: 'ТВ', tab: 'tourism' },
  { text: 'наружная реклама', tab: 'outdoor-advertising' },
  { text: 'радио', tab: 'radio' },
  { text: 'пресса', tab: 'press' },
  { text: 'интернет', tab: 'internet' },
  { text: 'спецпроекты', tab: 'special-projects' },
];

export const STUB_CASES: IModxCasePage = {
  object: {
    id: '',
    main_screen: {
      background: '',
      client: '',
      industries: [],
      open_hours: '',
      previev: null,
      preview: '',
      services: [],
      title: '',
    },
    order: 0,
    seo: {
      title: '',
      description: '',
    },
    BlocksList: [],
  },
};
