import { Autoplay } from 'swiper/modules';

export const settings = {
  autoplay: { delay: 0 },
  speed: 3000,
  loop: true,
  spaceBetween: 2,
  modules: [Autoplay],
  className: 'slider-logos',
};

export const settings2 = {
  ...settings,
  autoplay: { delay: 0, reverseDirection: true },
};
