import ArticleFromServer from '../../components/ArticleFromServer/ArticleFromServer';
import { Props } from './interfaces';
import './TextBlock.scss';

export const TextBlock = ({ text }: Props) => {
  return (
    <div className="case-text">
      <ArticleFromServer item={text} />
    </div>
  );
};
