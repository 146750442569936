import React from 'react';
import './MenuHamburger.scss';
import './MenuHamburger-media.scss';
import { Props } from './interfaces';

const MenuHamburgerStub = ({ onClick }: Props) => {
  return (
    <div className="menu-hamburger menu-hamburger_stub" onClick={onClick}>
      <span className="menu-hamburger__line line1"></span>
      <span className="menu-hamburger__line line2"></span>
      <span className="menu-hamburger__line line3"></span>
    </div>
  );
};

export default MenuHamburgerStub;
