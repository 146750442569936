import React, { useState } from 'react';
import { ICardTeam } from './interfaces';
import './CardTeam.scss';
import './CardTeam-media.scss';
import BaseLink from '../BaseLink/BaseLink';
import { baseURLModx } from '../../redux/services/APIRequestsModx';

const CardTeam = ({ image, position, name, link, id }: ICardTeam) => {
  const [imgVisible, setImgVisible] = useState(1);
  const img = (
    <img
      src={`${baseURLModx}/${image}`}
      className="card-team__image"
      alt="card-team"
      onError={() => setImgVisible(0)}
    ></img>
  );

  return (
    <div className="card-team">
      <div className="card-team__link-image">
        {imgVisible ? img : null}
        <div className="card-team__content">
          <div>
            <h4 className="card-team__name">{name}</h4>
            <p className="subtitle2 card-team__position">{position}</p>
          </div>
          <div className="card-team__link">
            <BaseLink text="Telegram" link={link} textClass="body2" out />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardTeam;
