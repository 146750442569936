import { useRef, useState } from 'react';
import { Socs } from '../../components/Footer/constants';
import Input from '../../components/Input/Input';
import BaseLink from '../../components/BaseLink/BaseLink';
import { EnumUrlsPages } from '../../services/Enum';
import './PageContacts.scss';
import './PageContacts-media.scss';
import { MediaContent, ValidationFormItems } from '../../services/GlobalFunctions';
import Content from '../../components/Content/Content';
import Tel from '../../components/Tel/Tel';
import MailTo from '../../components/MailTo/MailTo';
import Button from '../../components/Button/Button';
import InputPhone from '../../components/Input/InputPhone';
import { ServiceData } from '../../redux/services/ServiceRedux';
import { CSSTransition } from 'react-transition-group';
import SuccessContent from '../../components/SuccessContent/SuccessContent';
import ErrorTextServer from '../../components/ErrorTextServer/ErrorTextServer';

export const PageContacts = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');

  const [errorPhone, setErrorPhone] = useState<string | null>(null);
  const [serverText, setServerText] = useState<string | null>(null);

  const [successShow, setSuccessShow] = useState(false);
  const nodeRefSuccess = useRef(null);
  const OnTimeOut = () => {
    setTimeout(() => setSuccessShow(false), 2000);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!ValidationFormItems({ phone, setErrorPhone })) {
      return;
    }
    ServiceData.sendEmail({
      data: { name, phone, description },
      success,
      setServerText,
    });
  };

  const success = () => {
    setName('');
    setPhone('+7');
    setDescription('');
    setSuccessShow(true);
  };

  const title = <h2>Обсудим проект за чашечкой кофе?</h2>;

  const contacts = (
    <div>
      {MediaContent(title, null)}
      <div className="page-contacts__contacts">
        <div>
          <Tel phone="+7 (495) 269-03-17" className="body1" />
          <MailTo mail="target@ra-tm.ru" className="body1" />
        </div>

        <div className="footer__socs">
          {Socs.map(({ text, link }, i) => (
            <BaseLink link={link} text={text} out key={i} />
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <Content
      padding={{ paddingMain: { top: 80, bottom: 0 }, paddingMob: { top: 40, bottom: 40 } }}
      block={
        <div className="page-contacts">
          {MediaContent(null, title)}
          {MediaContent(contacts, null)}

          <form onSubmit={handleSubmit}>
            <Input
              placeholder="Имя"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              error={null}
            />
            <InputPhone
              placeholder="+7"
              value={phone}
              onChange={(e: any) => setPhone(e.target.value)}
              error={errorPhone}
            />
            <Input
              placeholder="Описание"
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
              error={null}
            />

            <div className="page-contacts__button">
              {serverText ? <ErrorTextServer serverText={serverText} /> : null}
              <Button text="Отправить" type="border" />
            </div>

            <p className="caption">
              Нажимая на кнопку "Отправить", вы соглашаетесь с политикой конфиденциальности и даете согласие
              на обработку персональных данных
            </p>
          </form>

          {MediaContent(null, contacts)}

          <CSSTransition
            in={successShow}
            nodeRef={nodeRefSuccess}
            timeout={{ appear: 200, enter: 0, exit: 200 }}
            classNames="css-transition-modal"
            unmountOnExit
            onEnter={OnTimeOut}
          >
            <SuccessContent refCss={nodeRefSuccess} />
          </CSSTransition>

          <div className="page-contacts__address">
            <p className="body2">Россия, Москва, 1-й Магистральный тупик д. 5А, 8-й этаж, офис В 801</p>
          </div>

          <div>
            <a href={EnumUrlsPages.policy} className="body2 footer__policy">
              Политика конфиденциальности
            </a>
            <p className="body2">2001–2024 ООО «Мэйн Таргет Групп»</p>
          </div>
        </div>
      }
    />
  );
};
