import React from 'react';
import './Counter.scss';
import './Counter-media.scss';

import CountUp from 'react-countup';
import { ICounter } from './interfaces';

const Counter = ({ count, suffix, prefix, text, fraction = 0 }: ICounter) => {
  return (
    <div className="counter">
      <h2 className="counter__field">
        <CountUp end={count} suffix={suffix} prefix={prefix} duration={3} separator="" decimals={fraction} />
      </h2>
      <p className="body1 counter__text">{text}</p>
    </div>
  );
};

export default Counter;
