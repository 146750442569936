import React from 'react';
import { Props } from './interfaces';
import './MenuTop.scss';
import './MenuTop-media.scss';
import LinkMenu from '../LinkMenu/LinkMenu';

const MenuTop = ({ menu, onClickLink }: Props) => {
  return (
    <menu className="menu-top">
      {menu.map((menu, i) => (
        <LinkMenu menu={menu} onClickLink={onClickLink} key={i} />
      ))}
    </menu>
  );
};

export default MenuTop;
