import { ICardTeam } from '../../components/CardTeam/interfaces';
import { ILogoPartner } from '../../components/SliderLogos/interfaces';
import { baseURLModx } from '../../redux/services/APIRequestsModx';
import { IModxImage, IModxTeam } from '../../redux/services/InterfacesModx';

export const formatTeam = (team: IModxTeam[]): ICardTeam[] => {
  const result: ICardTeam[] = [];
  team.forEach(({ name, photo, position, tg_link }, i) => {
    result.push({
      image: photo,
      position,
      name,
      link: tg_link,
      id: i,
    });
  });

  return result;
};

export const formatLogos = (logo: IModxImage[]): ILogoPartner[] => {
  const result: ILogoPartner[] = [];
  logo.forEach(({ image }, i) => {
    result.push({
      image: `${baseURLModx}/${image}`,
      alt: '',
    });
  });

  return result;
};

export const formatGallery = (image: IModxImage[]): string[] => {
  const result: string[] = [];
  image.forEach(({ image }, i) => {
    result.push(`${baseURLModx}/${image}`);
  });

  return result;
};
