import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './PageServices.scss';
import './PageServices-media.scss';
import Content from '../../components/Content/Content';
import Accordion from '../../components/Accordion/Accordion';
import { useEffect, useState } from 'react';
import { ServiceData } from '../../redux/services/ServiceRedux';
import { IModxServicesPage } from '../../redux/services/InterfacesModx';
import { Props } from './interfaces';
import { formatServices } from './functions';
import { STUB_SERVICES } from './constants';

const PageServices = ({ alertLoaded, widthWindow }: Props) => {
  const [data, setData] = useState<IModxServicesPage>(STUB_SERVICES);
  const {
    object: {
      services_block: { services },
    },
  } = data;

  useEffect(() => {
    ServiceData.getServicesPage({ alertLoaded, success });
  }, []);

  const success = (data: IModxServicesPage) => {
    setData(data);
  };

  return (
    <div className="page-services">
      <Content
        block={<h1 className="page-services__title">Услуги</h1>}
        padding={{ paddingMain: { top: 80, bottom: 0 }, paddingMob: { top: 40, bottom: 40 } }}
      />

      {formatServices(services).map((item, i) => (
        <Accordion {...item} key={i} />
      ))}
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(PageServices));
