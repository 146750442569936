import React from 'react';
import './ErrorTextServer.scss';
import { Props } from './interfaces';

const ErrorTextServer = ({ serverText }: Props) => {
  return (
    <div className="error-server">
      <p className="error-server__text" dangerouslySetInnerHTML={{ __html: serverText }}></p>
    </div>
  );
};

export default ErrorTextServer;
