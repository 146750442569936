import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ITabCategory } from '../../components/Tabs/interfaces';

export const goAnimation = () => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.to('.page-case__image', {
    scrollTrigger: {
      scrub: true,
      // markers: true,
      trigger: '.page-case__image',
      endTrigger: 'page-case__image',
      start: () => `300 100%`,
      end: () => `1500 95%`,
    },
    duration: 3,
    width: '100vw',
    height: '100vw',
    top: '-1200px',
  });
};

export const formatFilters = (filters: string[]): ITabCategory[] => {
  const result: ITabCategory[] = [];

  filters.forEach((tag) => {
    result.push({
      text: tag,
      tab: tag,
    });
  });

  console.log(result);
  return result;
};
