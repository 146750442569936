import { IModxAgencyPage } from '../../redux/services/InterfacesModx';

export const Statistic = [
  { count: 2001, prefix: 'c ', text: 'года на рынке рекламных услуг' },
  { count: 9, suffix: '', text: 'баллов в рейтинге надежности AdIndex', fraction: 1 },
  { count: 1000, suffix: '+', text: ' проектов с 2014 года', fraction: 1 },
  { count: 2018, prefix: 'c ', text: 'года член АКАР' },
  { count: 10, suffix: '+', text: 'лет средний стаж сотрудников' },
  { count: 2009, prefix: 'c ', text: 'года в рейтингах AdIndex и Sostav' },
];

export const STUB_AGENCY: IModxAgencyPage = {
  object: {
    id: '',
    seo: {
      title: '',
      description: '',
    },
    main_screen: {
      title: '',
      description: '',
      main_button_text: '',
      background: '',
    },
    clients_block: {
      title: '',
      logos: [],
    },
    team_block: {
      description: '',
      team: [],
    },
    gallery: [],
    logo: [],
  },
};
