import React, { useEffect, useState } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './PageAgency.scss';
import './PageAgency-media.scss';
import SliderGallery from '../../components/SliderGallery/SliderGallery';
import { Statistic, STUB_AGENCY } from './constants';
import ContentForMedia from '../../components/ContentForMedia/ContentForMedia';
import Button from '../../components/Button/Button';
import Content from '../../components/Content/Content';
import SliderLogos from '../../components/SliderLogos/SliderLogos';
import Counter from '../../components/Counter/Counter';
import Title from '../../components/Title/Title';
import { EnumUrlsPages } from '../../services/Enum';
import SliderTeam from '../../components/SliderTeam/SliderTeam';
import { IModxAgencyPage } from '../../redux/services/InterfacesModx';
import { ServiceData } from '../../redux/services/ServiceRedux';
import { Props } from './interfaces';
import { formatGallery, formatLogos, formatTeam } from './functions';

const PageAgency = ({ alertLoaded, widthWindow }: Props) => {
  const [data, setData] = useState<IModxAgencyPage>(STUB_AGENCY);
  const {
    object: {
      team_block: { team, description: teamDescription },
      logo,
      gallery,
    },
  } = data;

  useEffect(() => {
    ServiceData.getAgencyPage({ alertLoaded, success });
  }, []);

  const success = (data: IModxAgencyPage) => {
    setData(data);
  };

  return (
    <div className="page-agency">
      <div className="page-agency__main">
        <div className="page-agency__main-bg"></div>
        <ContentForMedia
          title="Агентство"
          text="Ответственный подход к процессу, пунктуальность и внимание к словам клиента"
          buttonsBlock={<Button text="Обсудить проект" link={EnumUrlsPages.contacts} />}
          mb="40px"
        />
      </div>

      <Content
        padding={{ paddingMain: { top: 160, bottom: 0 }, paddingMob: { top: 40, bottom: 0 } }}
        block={<Title title="Клиенты" color="dark" marginBottom={60} />}
      />

      <div className="page-agency__slider-logos">
        <SliderLogos padding="0" logosPartners={formatLogos(logo)} />
      </div>

      <Content
        padding={{ paddingMain: { top: 80, bottom: 80 }, paddingMob: { top: 40, bottom: 40 } }}
        block={
          <div className="page-agency__statistic">
            {Statistic.map((item, i) => (
              <Counter {...item} key={i} />
            ))}
          </div>
        }
      />

      <Content
        padding={{ paddingMain: { top: 80, bottom: 80 }, paddingMob: { top: 40, bottom: 60 } }}
        block={<Title title="Команда" color="dark" shortText={teamDescription} />}
      />
      <SliderTeam slides={formatTeam(team)} />

      <SliderGallery slides={formatGallery(gallery)} countDotsSlides={8} />
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(PageAgency));
