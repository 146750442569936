import './SuccessContent.scss';
import './SuccessContent-media.scss';

const SuccessContent = ({ refCss }: { refCss?: any }) => {
  return (
    <div ref={refCss} className="success-content">
      <h3 className="success-content__text">Заявка отправлена, свяжемся с вами в ближайшее время</h3>
    </div>
  );
};

export default SuccessContent;
