import React from 'react';
import { Props } from './interfaces';
import './HeaderMobPopup.scss';
import { Link } from 'react-router-dom';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../../redux/services/Imports';

import MenuHamburgerStub from '../MenuHamburger/MenuHamburgerStub';
import { MenuTopList } from '../Header/constants';
import BaseLink from '../../BaseLink/BaseLink';

const HeaderMobPopup = ({ closed, show, widthWindow }: Props) => {
  return (
    <div className={`popup-header${show ? ' popup-header_open' : ' popup-header_hide'}`}>
      <div className="popup-header__header">
        <Link to="/" onClick={closed} className="popup-header__logo-link">
          <img src={require('./img/logo.svg').default} className="popup-header__logo" alt="logo" />
        </Link>

        <MenuHamburgerStub onClick={closed} />
      </div>

      <div className="popup-header__content">
        {MenuTopList.map((menu, i) => (
          <Link to={menu.link} key={i} onClick={closed}>
            <h4>{menu.text}</h4>
          </Link>
        ))}
      </div>

      {/* <div className="popup-header__content">
        <BaseLink link={'/'} text="Telegram" />
      </div> */}
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(HeaderMobPopup));
